<template>
	<b-card title="Filters Input">
		<form @submit.prevent="submitFilter">
			<b-row>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Name:</label>
						<input 
							type="text" 
							class="form-control"
							placeholder="Search: Name"
							v-model="filter.name" 
						>
					</div>
				</b-col>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Phone:</label>
						<input 
							type="text" 
							class="form-control"
							placeholder="Search: Phone"
							v-model="filter.phone"
						>
					</div>
				</b-col>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Email:</label>
						<input 
							type="text" 
							class="form-control"
							placeholder="Search: Email"
							v-model="filter.email" 
						>
					</div>
				</b-col>
				<b-col cols="12" lg="3">
					<div class="form-group">
						<label>Date:</label>
						<flat-pickr
							v-model="filter.daterange"
							class="form-control"
							placeholder="Search: Date"
							:config="{ 
								mode: 'range',
								locale: {
									rangeSeparator: ' - ',
								},
							}"
						/>
					</div>
				</b-col>
				<b-col cols="12" lg="3">
					<div class="form-group">
						<label>EFIN:</label>
						<input 
							type="text" 
							class="form-control"
							placeholder="Search: EFIN"
							v-model="filter.efin" 
						>
					</div>
				</b-col>
				<b-col cols="12" lg="3">
					<div class="form-group">
						<label>NPWP:</label>
						<input 
							type="text" 
							class="form-control"
							placeholder="Search: NPWP"
							v-model="filter.npwp" 
						>
					</div>
				</b-col>
				<b-col cols="12" lg="3">
					<div class="form-group">
						<label>Verified:</label>
						<select 
							class="form-control"
							v-model="filter.verified"
						>
							<option value="" disabled>Search: Status</option>
							<option value="1">Verified</option>
							<option value="0">Unverified</option>
						</select>
					</div>
				</b-col>
			</b-row>
			<b-col cols="12" class="p-0 mt-2">
				<button
					type="submit"
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
				>
					Search
				</button>
				&nbsp;
				&nbsp;
				<button
					class="btn btn-outline-secondary"
					@click="resetFilter()" 
				>
					Reset
				</button>
			</b-col>
		</form>
		<div class="border__dash"></div>
		<b-table 
			striped 
			hover 
			:items="customers" 
			:fields="fields"
			:busy="isLoading"
			show-empty
			responsive
		>
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>

			<template v-slot:cell(no)="{ index }">
				{{ (currentPage - 1) * per_page + index + 1 }}
			</template>

			<template v-slot:cell(phone)="{ item }">
				{{item.phone ? item.phone : '-'}}
			</template>

			<template v-slot:cell(efin)="{ item }">
				{{item.efin ? item.efin : '-'}}
			</template>

			<template v-slot:cell(npwp)="{ item }">
				{{item.npwp ? item.npwp : '-'}}
			</template>

			<template v-slot:cell(status)="{ item }">
				<b-badge
					variant="light-success"
					v-if="item.verified == true"
				>
					Verified
				</b-badge>
				<b-badge
					variant="light-danger"
					v-if="item.verified == false"
				>
					Unverified
				</b-badge>
			</template>

			<template v-slot:cell(actions)="{ item }">
				<div class="d-flex" style="gap:12px;">
					<router-link
						:to="'/customers-detail/' + item.uuid"
						v-if="checkPermission('detail customer')"
					>
						<b-badge 
							variant="primary"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-send-icon`"
								icon="AlertCircleIcon"
								class="cursor-pointer"
								size="20"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Detail Data'"
							/>
						</b-badge>
					</router-link>

					<b-badge 
						variant="warning"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-info-icon`"
							icon="EditIcon"
							size="20"
							v-if="checkPermission('update customer')"
							v-b-modal="modalId(item.uuid)"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Edit Data'"
						/>
					</b-badge>
					<b-modal
						:id="'modal_' + item.uuid"
						centered
						title="Customer Info Edit"
						hide-footer
					>
						<form @submit.prevent="editCustomer(item)">
							<div class="form-group">
								<label>Nama</label>
								<input type="text" class="form-control" v-model="item.name">
							</div>
							<div class="form-group">
								<label>Email</label>
								<input type="email" class="form-control" v-model="item.email">
							</div>
							<div class="form-group">
								<label>No.Telepon</label>
								<input type="number" class="form-control" v-model="item.phone">
							</div>
							<div class="form-group">
								<label>Alamat</label>
								<input type="text" class="form-control" v-model="item.address">
							</div>
							<div class="form-group">
								<label>EFIN</label>
								<input type="text" class="form-control" v-model="item.efin">
							</div>
							<div class="form-group">
								<label>NPWP</label>
								<input type="text" class="form-control" v-model="item.npwp">
							</div>
							<div class="form-group">
								<label>Male</label>
								<div class="demo-inline-spacing">
									<div class="custom-control custom-radio mt-0">
										<input 
											name="inlineRadioOptions1" 
											class="custom-control-input" 
											type="radio" 
											id="radio1" 
											value="male" 
											:checked="item.gender == 'male'"
										>
										<label 
											class="custom-control-label" 
											for="radio1"
										>
											Laki-Laki
										</label>
									</div>
									<div class="custom-control custom-radio mt-0">
										<input 
											name="inlineRadioOptions1" 
											class="custom-control-input" 
											type="radio" 
											id="radio2"
											value="female"
											:checked="item.gender == 'female'"
										>
										<label 
											class="custom-control-label" 
											for="radio2"
										>
											Perempuan
										</label>
									</div>
								</div>
							</div>
							<button
								variant="primary"
								class="btn waves-effect waves-float waves-light btn-primary mt-2"
							>
								Save
							</button>
						</form>
					</b-modal>
					<b-badge 
						variant="danger"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-preview-icon`"
							icon="TrashIcon"
							size="20"
							@click="deleteUser(item.uuid)"
							v-if="checkPermission('delete customer')"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Delete Data'"
						/>
					</b-badge>
				</div>
			</template>
		</b-table>
		<div
			v-if="result.total > 0"
			class="m-1"
		>
			<div class="row">
				<div class="col mb-1">
					<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
				</div>
				<div class="col">
					<pagination
						:data="result"
						:limit="4"
						align="right"
						@pagination-change-page="getData"
					/>
				</div>
			</div>
		</div>
	</b-card>
</template>

<script>
import 
  { 
	BRow,
	BCol,
    BCard,
    BCardText,
    BTable,
    VBModal,
	BButton,
    BPagination,
    BFormGroup, 
	BFormRadio, 	
	BSpinner,
	BBadge,
	VBTooltip
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
import flatPickr from 'vue-flatpickr-component'

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardText,
		BTable,
		BButton,
		BPagination,
		BFormGroup, 
		BFormRadio, 
		BSpinner,
		BBadge,
		flatPickr,
		// DateRangePicker
	},

	data() {
		return {
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: ['no', 'name', 'phone', 'email', 'efin', 'npwp' ,'registered_at', 'status' ,'actions'],
			customers: [],
			filter: {
				name: '',
				email: '',
				phone: '',
				efin: '',
				npwp: '',
				verified: '',
				daterange: null,
			},
			per_page: 10
		}
	},

	directives: {
	'b-tooltip': VBTooltip,
    'b-modal': VBModal,
		Ripple,
	},

	setup(props) {
		return {
			checkPermission,
			errorNotification,
			successNotification,
		}
	},

	methods: {
		modalId(id) {
			return 'modal_' + id;
		},
		resetFilter(){
			this.filter = {
				name: '',
				email: '',
				phone: '',
				efin: '',
				npwp: '',
				daterange: null
			}
		},
		submitFilter() {
			this.getData(this.currentPage)
		},

		getData(page) { //page = 1
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
			queryParams.per_page = this.per_page
			queryParams.page = page
			this.$http.get('/admin/customers', {
				params: queryParams,
			})
				.then(response => {
				this.result = response.data.data
				this.customers = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
				})
		},
		editCustomer(item) {
			item._method = 'PATCH'
			this.$http.post('admin/customers/' + item.uuid, item)
			.then(response => {
				this.getData()
				successNotification(this, 'Success', 'Customer sukses diedit!')
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Opps!', 'Ada Kendala Teknis')
				}
			})
			this.$nextTick(() => {
				this.$bvModal.hide('modal_' + item.uuid)
			})
		},
		deleteUser(item) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus Customer ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				type: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/customers/' + item)
						this.getData(this.currentPage)
						successNotification(this, 'Success', 'Customer Sukses Dihapus!')
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					errorNotification(this, 'Oops!', 'Ada Kendala Teknis')
				}
			})
		}
	},

  created() {
		this.getData();
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
